<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ Team }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ Team }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="team-section inner-team ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6"></div>
                <div class="col-lg-4 col-md-5 col-sm-6">
                    <div class="woocommerce-topbar-ordering">
                        <select class="selectTeam">
                            <option
                                *ngFor="let item of filterSort"
                                [value]="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center alert alert-danger" *ngIf="msg">
            {{ msgText }}
        </div>
        <div class="row align-items-center" *ngIf="instructors.length > 0">
            <div
                class="col-lg-3 col-md-6 col-sm-6"
                *ngFor="let instructor of instructors"
            >
                <div class="single-team-member">
                    <div class="member-image">
                        <img
                            src="{{ baseUrl + '/' + instructor.image }}"
                            alt="image"
                        />
                    </div>

                    <div class="member-content">
                        <h3>
                            <a>{{
                                instructor.firstName + " " + instructor.lastName
                            }}</a>
                        </h3>
                        <span>{{ instructor.pio }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
