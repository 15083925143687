<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ Blog }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ Blog }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs">
                <div class="blog-item">
                    <div class="blog-image">
                        <a [routerLink]="['/blog-details/' + blog?.id]">
                            <img src="{{ baseUrl + '/' + blog.image }}" alt="image" /></a>
                    </div>

                    <div class="single-blog-item">
                        <div class="blog-content">
                            <a [routerLink]="['/blog-details/' + blog?.id]">
                                <h3>{{ blog[lang + "_title"] }}</h3>
                            </a>
                            <!-- <p>{{ blog[lang + "_desc"].slice(0, 150) }}</p>-->
                        </div>

                        <ul class="blog-list">
                            <li>Created At: {{ getDate(blog.createdAt) }}</li>
                            <li>Posted By: {{ Admin }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a class="prev page-numbers" class="{{ offset <= 0 ? 'disable' : 'enable' }}" (click)="offset = offset - 1; changeOffset()"><i class="fas fa-angle-double-left"></i></a>
                    <a aria-current="page" class="page-numbers {{ i == offset ? 'current' : '' }}" *ngFor="let num of number; let i = index" (click)="offset = i; changeOffset()">{{ i + 1 }}</a>
                    <a class="next page-numbers" class="{{
                            offset >= total_pages - 1 ? 'disable' : 'enable'
                        }}" (click)="offset = offset + 1; changeOffset()"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>