import { Component, OnInit } from "@angular/core";
import { DefaultService } from "src/app/services/default.service";
import { InstructorService } from "src/app/services/instructor.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;
@Component({
    selector: "app-team",
    templateUrl: "./team.component.html",
    styleUrls: ["./team.component.scss"],
})
export class TeamComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;

    filterSort = [
        {
            id: 1,
            value: "id",
            name: "Default sorting",
            type: "ASC",
        },

        {
            id: 2,
            value: "firstName",
            name: "Alphabetical a-z",
            type: "ASC",
        },
        {
            id: 3,
            value: "firstName",
            name: "Alphabetical z-a",
            type: "DESC",
        },
    ];

    sort = null;
    msg = false;
    msgText = "Wait a Minute!";
    instructors = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    Team: any;
    Home: any;

    constructor(
        private _instructor: InstructorService,
        private _defult: DefaultService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
        this.getData();
        var app = this;
        $(document).ready(function () {
            $(".selectTeam").change(function () {
                app.getData($(this).val(), 2, 0);
            });
        });
    }

    getLabels() {
        this.translate.get(["Team", "Home"]).subscribe((res) => {
            this.Team = res.Team;
            this.Home = res.Home;
        });
    }

    getData(sort?: any, limit?: any, offset?: any) {
        this.msgText = "Wait a Minute!";
        this.msg = false;
        var sortValue = null;
        var sortType = null;

        if (sort != null) {
            let sort2 = this.filterSort.find((e) => e.id == sort);
            sortValue = sort2.value;
            sortType = sort2.type;
        }

        /*let per_page=this.limit
  //let page=this.offset

  if(limit!=null){
   per_page=limit
  }

  if(offset!=null){
      page=offset
  }*/

        this._instructor
            .getAllInstructors("", sortValue, sortType, 0, 999999999999)
            .subscribe(
                (res) => {
                    //console.log(res);
                    // to check if more instructors exists
                    if (res.apiStatus) {
                        this.instructors = res.data;
                        if (this.instructors.length == 0) {
                            this.msgText = "No Data Were Found!";
                            this.msg = true;
                        } else {
                            /*  var count = res.count
        this.total_pages= Math.ceil(count/this.limit);
        this.number = Array.from({length:this.total_pages},(v,k)=>k+1);*/
                        }
                    }
                },
                (err) => {
                    this.msg = true;
                    this.msgText = "No Data Were Found!";
                    //console.log(err);
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }
}
