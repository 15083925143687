import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";
import { TranslateService } from "@ngx-translate/core";
import { DefaultService } from "src/app/services/default.service";
import { InstructorService } from "src/app/services/instructor.service";

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
    msg: any = null;
    lang = "en";
    Home: any;
    Signup: any;
    CreateyourAccount: any;
    required: any;
    youmustentervalidemail: any;
    FirstName: any;
    LastName: any;
    Email: any;
    Password: any;
    passwordlenghtmustbe6atleast: any;
    Alreadyaregistereduser: any;
    SignupNow: any;
    Login: any;
    SignStudentNow: any;
    SignInstructorNow: any;

    registerForm = new FormGroup({
        firstName: new FormControl("", [
            Validators.required,
            Validators.minLength(3),
        ]),
        lastName: new FormControl("", [
            Validators.required,
            Validators.minLength(3),
        ]),
        email: new FormControl("", [Validators.required, Validators.email]),
        password: new FormControl("", [
            Validators.required,
            Validators.minLength(6),
        ]),
    });
    constructor(
        private router: Router,
        private _instructor: InstructorService,
        private _student: StudentService,
        public translate: TranslateService,
        private _defult: DefaultService,
        private auth: AuthenticationService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "Signup",
                "CreateyourAccount",
                "required",
                "youmustentervalidemail",
                "FirstName",
                "LastName",
                "Email",
                "Password",
                "passwordlenghtmustbe6atleast",
                "Alreadyaregistereduser",
                "SignupNow",
                "Login",
                "SignStudentNow",
                "SignInstructorNow",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.Signup = res.Signup;
                this.CreateyourAccount = res.CreateyourAccount;
                this.required = res.required;
                this.youmustentervalidemail = res.youmustentervalidemail;
                this.FirstName = res.FirstName;
                this.LastName = res.LastName;
                this.Email = res.Email;
                this.Password = res.Password;
                this.passwordlenghtmustbe6atleast =
                    res.passwordlenghtmustbe6atleast;
                this.Alreadyaregistereduser = res.Alreadyaregistereduser;
                this.SignupNow = res.SignupNow;
                this.Login = res.Login;
                this.SignStudentNow = res.SignStudentNow;
                this.SignInstructorNow = res.SignInstructorNow;
            });
    }

    signAsInstructor() {
        if (this.registerForm.valid) {
            this._instructor
                .registerInstructor(
                    this.registerForm.value.firstName,
                    this.registerForm.value.lastName,
                    this.registerForm.value.email,
                    this.registerForm.value.password
                )
                .subscribe(
                    (res) => {
                        if (res.apiStatus) {
                            /* this.auth.login({
                  token: res.token,
                  image: res.user.image,
                  firstName: res.user.firstName,
                  lastName: res.user.lastName,
                  email:res.user.email,
                  phone:res.user.phoneNumber,
                  id: res.user.id,
                  status:res.user.status
                });*/

                            this.router.navigateByUrl("/");
                        } else {
                            if (res.code == 1) {
                                this.msg = "this email already exists";
                            } else if (res.code == 2) {
                                this.msg = "Error .";
                            }
                        }
                    },
                    (err) => {
                        //console.log(err);
                    }
                );
        } else {
        }
    }

    signAsStudent() {
        if (this.registerForm.valid) {
            this._student
                .registerStudent(
                    this.registerForm.value.firstName,
                    this.registerForm.value.lastName,
                    this.registerForm.value.email,
                    this.registerForm.value.password
                )
                .subscribe(
                    (res) => {
                        if (res.apiStatus) {
                            this.auth.login({
                                id: res.user.id,
                                firstName: res.user.firstName,
                                lastName: res.user.lastName,
                                token: res.token,
                                email: res.user.email,
                                phone: res.user.phoneNumber,
                                image: res.user.image,
                                status: res.user.status,
                                type: "student",
                            });

                            if (localStorage.getItem("previous_link")) {
                                this.router.navigateByUrl(
                                    localStorage.getItem("previous_link")
                                );
                            } else {
                                this.router.navigateByUrl("/");
                            }
                        } else {
                            if (res.code == 1) {
                                this.msg = "this email already exists";
                            } else if (res.code == 2) {
                                this.msg = "Error .";
                            }
                        }
                    },
                    (err) => {
                        //console.log(err);
                    }
                );
        } else {
        }
    }

    get firstName() {
        return this.registerForm.get("firstName");
    }
    get lastName() {
        return this.registerForm.get("lastName");
    }
    get email() {
        return this.registerForm.get("email");
    }
    get password() {
        return this.registerForm.get("password");
    }
}
