import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
    FormControl,
    FormGroup,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { CartService } from "src/app/services/cart.service";
import { DefaultService } from "src/app/services/default.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { environment as env } from "../../../../environments/environment.prod";
import {
    StripeService,
    StripePaymentElementComponent,
    StripeCardNumberComponent,
} from "ngx-stripe";
import {
    StripeCardElementOptions,
    StripeElementsOptions,
    PaymentIntent,
    StripeElements,
    StripeCardElement,
} from "@stripe/stripe-js";
import { CouponsService } from "src/app/services/coupons.service";
import { OrderService } from "src/app/services/order.service";

@Component({
    selector: "app-checkout",
    templateUrl: "./checkout.component.html",
    styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
    // @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
    elements: StripeElements;
    card: StripeCardElement;

    user: any = {};
    msg: any = null;
    cartItems = [];
    total: any = 0;
    checkoutForm: any;
    lang = "en";
    done: any;
    Home: any;
    BillingDetails: any;
    Checkout: any;
    LastName: any;
    FirstName: any;
    Email: any;
    youmustentervalidemail: any;
    phone: any;
    Numbermustbeatleast8numbers: any;
    NameOnCard: any;
    writevalidname: any;
    CardNumber: any;
    writevalidcardnumber: any;
    ExpirationMonth: any;
    ExpirationYea: any;
    CardCVVNumber: any;
    Country: any;
    TownCity: any;
    State: any;
    Address: any;
    PostcodeZip: any;
    required: any;
    OrderNotes: any;
    YourOrder: any;
    ProductName: any;
    price: any;
    PlaceOrder: any;
    ExpirationYear: any;
    couponId: any = null;
    email2: any = null;
    discount = 0;
    name = null;
    from: any = 1;
    elementsOptions: StripeElementsOptions = {
        locale: "en",
    };
    actualItems = "";

    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: "#666EE8",
                color: "#31325F",
                fontWeight: "300",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: "18px",
                "::placeholder": {
                    color: "#212529",
                },
            },
        },
    };

    stripeTest: FormGroup;
    constructor(
        private auth: AuthenticationService,
        private _cart: CartService,
        private _coupon: CouponsService,
        private _order: OrderService,
        private router: Router,
        private _defult: DefaultService,
        public translate: TranslateService,
        private stripeService: StripeService,
        private fb: FormBuilder,
        private http: HttpClient,
        private router2: ActivatedRoute
    ) {
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
            this.from = this.router2.snapshot.paramMap.get("from");
            localStorage.removeItem("previous_link");
        } else {
            this.router.navigateByUrl("/login");
        }
    }

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }

        if (this.user) {
            this.email2 = this.user.email;
            this.name = this.user.firstName + " " + this.user.lastName;

            this.getLabels();
            this.stripeTest = new FormGroup({
                nameOnCard: new FormControl("", [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern("^[A-Za-z][A-Za-z -]*$"),
                ]),
                email: new FormControl(this.user.email, [
                    Validators.required,
                    Validators.email,
                ]),

                firstName: new FormControl(this.user.firstName, [
                    Validators.required,
                ]),
                lastName: new FormControl(this.user.lastName, [
                    Validators.required,
                ]),
                mobile: new FormControl(this.user.phone, [
                    Validators.required,
                    Validators.minLength(8),
                ]),
                coupon: new FormControl("", []),
            });
            /* this.checkoutForm = new FormGroup({
                nameOnCard: new FormControl("", [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern("^[A-Za-z][A-Za-z -]*$"),
                ]),
                cardNumber: new FormControl("", [Validators.required]),
                expirationMonth: new FormControl("", [
                    Validators.required,
                    // Validators.minLength(4),
                    // Validators.maxLength(5),
                    //  Validators.min(1),
                    //  Validators.max(12),
                ]),
                expirationYear: new FormControl("", [Validators.required]),
                cardCVVNumber: new FormControl("", [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(3),
                    Validators.min(111),
                    Validators.max(999),
                ]),
                country: new FormControl("", [Validators.required]),
                address: new FormControl("", [Validators.required]),
                city: new FormControl("", [Validators.required]),
                state: new FormControl("", [Validators.required]),
                postal: new FormControl("", [Validators.required]),
                email: new FormControl(this.user.email, [
                    Validators.required,
                    Validators.email,
                ]),

                firstName: new FormControl(this.user.firstName, [
                    Validators.required,
                ]),
                lastName: new FormControl(this.user.lastName, [
                    Validators.required,
                ]),
                mobile: new FormControl(this.user.phone, [
                    Validators.required,
                    Validators.minLength(8),
                ]),
            });*/
            this.stripeService
                .elements(this.elementsOptions)
                .subscribe((elements) => {
                    this.elements = elements;
                    // Only mount the element the first time
                    if (!this.card) {
                        this.card = this.elements.create(
                            "card",
                            this.cardOptions
                        );
                        this.card.mount("#card-element");
                    }
                });
        } else {
            this.router.navigate(["/login"]);
        }

        this.getData();
    }

    getLabels() {
        /*  this.translate
            .get([
                "Home",
                "Checkout",
                "BillingDetails",
                "FirstName",
                "LastName",
                "Email",
                "youmustentervalidemail",
                "phone",
                "Numbermustbeatleast8numbers",
                "NameOnCard",
                "writevalidname",
                "CardNumber",
                "writevalidcardnumber",
                "ExpirationMonth",
                "ExpirationYear",
                "CardCVVNumber",
                "Country",
                "TownCity",
                "State",
                "Address",
                "Postcode/Zip",
                "required",
                "OrderNotes",
                "YourOrder",
                "ProductName",
                "price",
                "PlaceOrder",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.BillingDetails = res.BillingDetails;
                this.Checkout = res.Checkout;
                this.LastName = res.LastName;
                this.FirstName = res.FirstName;
                this.Email = res.Email;
                this.youmustentervalidemail = res.youmustentervalidemail;
                this.phone = res.phone;
                this.Numbermustbeatleast8numbers =
                    res.Numbermustbeatleast8numbers;
                this.NameOnCard = res.NameOnCard;
                this.writevalidname = res.writevalidname;
                this.CardNumber = res.CardNumber;
                this.writevalidcardnumber = res.writevalidcardnumber;
                this.ExpirationMonth = res.ExpirationMonth;
                this.ExpirationYear = res.ExpirationYear;
                this.CardCVVNumber = res.CardCVVNumber;
                this.Country = res.Country;
                this.TownCity = res.TownCity;
                this.State = res.State;
                this.Address = res.Address;
                this.PostcodeZip = res.PostcodeZip;
                this.required = res.required;
                this.OrderNotes = res.OrderNotes;
                this.YourOrder = res.YourOrder;
                this.ProductName = res.ProductName;
                this.price = res.price;
                this.PlaceOrder = res.PlaceOrder;
            });*/
    }

    getData() {
        if (this.from == 1) {
            this._cart.loadCart();
            this.cartItems = this._cart.getItems();
        } else {
            this._cart.loadCart2();
            this.cartItems = this._cart.getItems2();
        }

        this.checkItems(this.cartItems);
    }

    checkItems(items) {
        items.forEach((e, index) => {
            this._cart.checkItems(e, this.user.id).subscribe((res) => {
                if (res.apiStatus) {
                    this.cartItems = this.cartItems.filter((e2) => e2 !== e);
                    this.actualItems =
                        this.actualItems + "/" + e[this.lang + "_name"];
                }

                if (index == items.length - 1) {
                    this.total = this.getTotal();

                    if (this.actualItems != "") {
                        Swal.fire({
                            title: "",
                            text: `Already you have ${this.actualItems}, we removed it from your cart`,
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ok",
                            showLoaderOnConfirm: true,
                        }).then((result) => {});
                    }
                }
            });
        });
    }

    getTotal() {
        var total = 0;
        this.cartItems.forEach((e) => {
            var price = e.price;

            if (e.discount > 0) {
                price = e.discount;
            }

            total = Number(total) + Number(price);
        });
        return total;
    }

    checkout(with_coupon) {
        let total = this.getTotal();
        if (this.discount > 0) {
            total = total - (this.total * this.discount) / 100;
        }

        this._order
            .createOne(
                with_coupon,
                this.couponId,
                this.cartItems.length,
                this.user.id,
                total,
                this.email2,
                this.name
            )
            .subscribe((res) => {
                if (res.apiStatus) {
                    this.cartItems.forEach((e, index) => {
                        // console.log(e);
                        var before_price = e.price;
                        var after_price = e.price;

                        if (e.discount > 0) {
                            after_price = e.discount;
                        }

                        this._cart
                            .createOne(
                                this.user.id,
                                e.id,
                                before_price,
                                after_price,
                                e.cat_id,
                                res.data.id,
                                this.email2,
                                this.name
                            )
                            .subscribe(
                                (res) => {
                                    if (res.message == "cart added") {
                                        if (
                                            this.cartItems.length - 1 ==
                                            index
                                        ) {
                                            if (this.from == 1) {
                                                this._cart.clearCart();
                                            } else {
                                                this._cart.clearCart2();
                                            }
                                            this.done = true;
                                            this.msg =
                                                "order done total price $ " +
                                                after_price;
                                            this.stripeTest.reset();
                                            this.cartItems = [];
                                        }
                                    } else if (res.message == "added before") {
                                        if (this.from == 1) {
                                            this._cart.clearCart();
                                        } else {
                                            this._cart.clearCart2();
                                        }
                                        this.done = true;
                                        this.msg = "you already have it";

                                        this.stripeTest.reset();
                                        this.cartItems = [];
                                    }
                                },
                                (err) => {
                                    //console.log(err);
                                    this.done = false;
                                    this.msg = "Something went wrong";
                                }
                            );
                    });
                }
            });
    }

    pay(): void {
        this.done = true;
        this.msg = "Please, wait a minute";
        if (this.stripeTest.valid) {
            let total = this.getTotal();

            if (
                this.stripeTest.value.coupon &&
                this.stripeTest.value.coupon.length > 0
            ) {
                this._coupon
                    .checkCoupon(this.stripeTest.value.coupon)
                    .subscribe(
                        (res) => {
                            // to check if more books exists
                            if (res.apiStatus) {
                                this.couponId = res.data.id;
                                this.discount = res.data.discount;
                                total =
                                    total - (total * res.data.discount) / 100;
                                this.completePayment(total, 1);
                            } else {
                                this.done = false;
                                this.msg = "coupon code  is not valid";
                            }
                        },
                        (err) => {
                            this.done = false;
                            this.msg = "coupon code  is not valid";
                        }
                    );
            } else {
                this.completePayment(total, 0);
            }
        } else {
            this.done = false;
            this.msg = "please check your information";
        }
    }

    completePayment(total, with_coupon) {
        //this.updateCoupon()
        //this.checkout(with_coupon);

        this.createPaymentIntent(total)
            .pipe(
                switchMap((pi) =>
                    this.stripeService.confirmCardPayment(pi.client_secret, {
                        payment_method: {
                            card: this.card,
                            billing_details: {
                                name: this.stripeTest.value.nameOnCard,
                                email: this.stripeTest.value.email,
                            },
                        },
                    })
                )
            )
            .subscribe((result) => {
                if (result.error) {
                    // Show error to your customer (e.g., insufficient funds)

                    this.done = false;
                    this.msg = result.error.message;
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === "succeeded") {
                        // Show a success message to your customer
                        if (with_coupon == 1) this.updateCoupon();
                        this.checkout(with_coupon);
                    }
                }
            });
    }

    updateCoupon() {
        this._coupon.updateStatus(this.couponId, 0).subscribe(
            (res) => {
                // console.log(res)
            },
            (err) => {
                // console.log(err)
            }
        );
    }

    createPaymentIntent(amount: any): Observable<PaymentIntent> {
        return this.http.post<PaymentIntent>(
            `${env.BaseUrl}/pay/create-payment-intent`,
            { amount: amount }
        );
    }
    get firstName() {
        return this.stripeTest.get("firstName");
    }
    get lastName() {
        return this.stripeTest.get("lastName");
    }
    get email() {
        return this.stripeTest.get("email");
    }
    get mobile() {
        return this.stripeTest.get("mobile");
    }

    get coupon() {
        return this.stripeTest.get("coupon");
    }

    get nameOnCard() {
        return this.stripeTest.get("nameOnCard");
    }
    /*get cardNumber() {
        return this.stripeTest.get("cardNumber");
    }
    get expirationMonth() {
        return this.checkoutForm.get("expirationMonth");
    }
    get expirationYear() {
        return this.checkoutForm.get("expirationYear");
    }
    get cardCVVNumber() {
        return this.checkoutForm.get("cardCVVNumber");
    }

    get country() {
        return this.checkoutForm.get("country");
    }
    get city() {
        return this.checkoutForm.get("city");
    }
    get state() {
        return this.checkoutForm.get("state");
    }
    get postal() {
        return this.checkoutForm.get("postal");
    }
    get address() {
        return this.checkoutForm.get("address");
    }*/
}
