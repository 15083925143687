import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";
import { TranslateService } from "@ngx-translate/core";
import { DefaultService } from "src/app/services/default.service";
import { InstructorService } from "src/app/services/instructor.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    msg: any = null;
    lang = "en";
    Home: any;
    Signup: any;
    Login: any;
    WelcomeBack: any;
    required: any;
    youmustentervalidemail: any;
    Pleaselogintoyouraccount: any;
    LoginForm: any;
    Email: any;
    Password: any;
    passwordlenghtmustbe6atleast: any;
    KeepmeLogin: any;
    SignupNow: any;
    ForgotPassword: any;
    LoginNow: any;
    Donthaveaccount: any;
    LoginStudentNow: any;
    LoginInstructorNow: any;
    ForgetYourPassword: any;

    loginForm = new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
        password: new FormControl("", [
            Validators.required,
            Validators.minLength(6),
        ]),
    });
    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private _student: StudentService,
        private _instructor: InstructorService,
        public translate: TranslateService,
        private _defult: DefaultService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "Login",
                "WelcomeBack",
                "required",
                "youmustentervalidemail",
                "Pleaselogintoyouraccount",
                "loginForm",
                "Email",
                "Password",
                "passwordlenghtmustbe6atleast",
                "KeepmeLogin",
                "ForgotPassword",
                "LoginNow",
                "Donthaveaccount",
                "LoginStudentNow",
                "LoginInstructorNow",
                "ForgetYourPassword",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.Login = res.Login;
                this.WelcomeBack = res.WelcomeBack;
                this.required = res.required;
                this.youmustentervalidemail = res.youmustentervalidemail;
                this.Pleaselogintoyouraccount = res.Pleaselogintoyouraccount;
                this.LoginForm = res.LoginForm;
                this.Email = res.Email;
                this.Password = res.Password;
                this.passwordlenghtmustbe6atleast =
                    res.passwordlenghtmustbe6atleast;
                this.KeepmeLogin = res.KeepmeLogin;
                this.ForgotPassword = res.ForgotPassword;
                this.LoginStudentNow = res.LoginStudentNow;
                this.LoginInstructorNow = res.LoginInstructorNow;
                this.Donthaveaccount = res.Donthaveaccount;
                this.ForgetYourPassword = res.ForgetYourPassword;
            });
    }

    loginAsStudent() {
        if (this.loginForm.valid) {
            this._student
                .loginStudent(
                    this.loginForm.value.email,
                    this.loginForm.value.password
                )
                .subscribe(
                    (res) => {
                        if (res.apiStatus) {
                            this.auth.login({
                                id: res.user.id,
                                firstName: res.user.firstName,
                                lastName: res.user.lastName,
                                token: res.token,
                                email: res.user.email,
                                phone: res.user.phoneNumber,
                                image: res.user.image,
                                status: res.user.status,
                                type: "student",
                            });

                            if (localStorage.getItem("previous_link")) {
                                this.router.navigateByUrl(
                                    localStorage.getItem("previous_link")
                                );
                            } else {
                                this.router.navigateByUrl("/");
                            }
                        } else {
                            if (res.code == 1) {
                                this.msg = "the email not exist.";
                            } else if (res.code == 2) {
                                this.msg = "Not Valid Password or Email .";
                            } else if (res.code == 3) {
                                this.msg =
                                    "Your account has not been approved.";
                            } else if (res.code == 4) {
                                this.msg =
                                    "Your account has been suspended for security reasons, please contact support .";
                            }
                        }
                    },
                    (err) => {
                        // console.log(err);
                    }
                );
        } else {
        }
    }

    loginAsInstructor() {
        if (this.loginForm.valid) {
            this._instructor
                .loginInstructor(
                    this.loginForm.value.email,
                    this.loginForm.value.password
                )
                .subscribe(
                    (res) => {
                        //console.log(res);
                        if (res.apiStatus) {
                            this.auth.login({
                                id: res.user.id,
                                firstName: res.user.firstName,
                                lastName: res.user.lastName,
                                token: res.token,
                                email: res.user.email,
                                phone: res.user.phoneNumber,
                                image: res.user.image,
                                status: res.user.status,
                                type: "instructor",
                            });

                            this.router.navigateByUrl("/");
                        } else {
                            if (res.code == 1) {
                                this.msg = "the email not exist.";
                            } else if (res.code == 2) {
                                this.msg = "Not Valid Password or Email .";
                            } else if (res.code == 3) {
                                this.msg =
                                    "Your account has not been approved.";
                            } else if (res.code == 4) {
                                this.msg =
                                    "Your account has been suspended for security reasons, please contact support .";
                            }
                        }
                    },
                    (err) => {
                        //console.log(err);
                    }
                );
        } else {
        }
    }

    get email() {
        return this.loginForm.get("email");
    }
    get password() {
        return this.loginForm.get("password");
    }
}
