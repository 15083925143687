import { Component, OnInit } from "@angular/core";
import { DefaultService } from "src/app/services/default.service";
import { PostService } from "src/app/services/post.service";
import { ReviewService } from "src/app/services/review.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import { PageService } from "src/app/services/page.service";
declare var $: any;
@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    lang = "en";
    reviews = [];
    posts = [];
    aboutUs: any = {};
    whatWeDo: any = {};
    clientsSay: any = {};
    number = [1, 2];
    baseUrl: string = environment.BaseUrl;
    SlideOptions = {
        loop: true,
        nav: false,
        dots: true,
        smartSpeed: 2000,
        rtl: true,
        margin: 30,
        center: true,
        active: true,
        autoplayHoverPause: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
    };
    AboutUs: any;
    aboutUsImage: any;
    aboutUsDes: any;
    aboutUsTitle: any;

    whatWeDoTitle: any;
    whatWeDoImage: any;
    whatWeDoDes: any;
    clientsSayDes: any;

    Home: any;
    WhatWedo: any;

    constructor(
        private _review: ReviewService,
        private _defult: DefaultService,
        private _page: PageService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
        this.getData();
        this.getReviews();
    }

    getLabels() {
        this.translate.get(["AboutUs", "Home", "WhatWeDo"]).subscribe((res) => {
            this.AboutUs = res.AboutUs;
            this.Home = res.Home;
            this.WhatWedo = res.WhatWeDo;
        });
    }

    getData() {
        this._page.getPages("About", this._defult.getDefult().langId).subscribe(
            (res) => {
                if (res.error == 0) {
                    this.posts = JSON.parse(res.data.Keys);

                    this.AboutUs = this.posts.find(
                        (e) => e.id == "aboutUs"
                    ).value;
                    this.whatWeDo = this.posts.find(
                        (e) => e.id == "whatWeDo"
                    ).value;
                    this.clientsSay = this.posts.find(
                        (e) => e.id == "clientsSay"
                    ).value;

                    this.aboutUsImage = this.posts.find(
                        (e) => e.id == "aboutUsImage"
                    ).value;
                    this.aboutUsDes = this.posts.find(
                        (e) => e.id == "aboutUsDes"
                    ).value;
                    this.aboutUsTitle = this.posts.find(
                        (e) => e.id == "aboutUsTitle"
                    ).value;

                    this.whatWeDoTitle = this.posts.find(
                        (e) => e.id == "whatWeDoTitle"
                    ).value;
                    this.whatWeDoImage = this.posts.find(
                        (e) => e.id == "whatWeDoImage"
                    ).value;
                    this.whatWeDoDes = this.posts.find(
                        (e) => e.id == "whatWeDoDes"
                    ).value;
                    this.clientsSayDes = this.posts.find(
                        (e) => e.id == "clientsSayDes"
                    ).value;

                    this.Home = this.posts.find((e) => e.id == "Home").value;
                }
            },
            (err) => {
                //console.log(err)
            }
        );
    }

    getReviews() {
        {
            this._review.getAllReviews().subscribe(
                (res) => {
                    if (res.apiStatus) {
                        this.reviews = res.data;

                        this.number = Array.from(
                            { length: this.reviews.length },
                            (v, k) => k + 1
                        );
                    }
                },
                (err) => {
                    //console.log(err)
                }
            );
        }
    }

    getDate(date) {
        let d = new Date(date);
        let hours = d.getUTCHours(); // Hours
        let min = d.getUTCMinutes(); //min
        let sec = d.getUTCSeconds(); //sec
        let day = d.getUTCDate(); // day
        let mon = d.getUTCMonth(); //monthes
        let year = d.getUTCFullYear(); //year

        return day + "/" + mon + "/" + year + " at " + hours + ":" + min;
    }
}
