import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/services/auth.service";
import { CartService } from "src/app/services/cart.service";
import { DefaultService } from "src/app/services/default.service";
import { environment } from "src/environments/environment.prod";
declare var $: any;
@Component({
    selector: "app-navbar-two",
    templateUrl: "./navbar-two.component.html",
    styleUrls: ["./navbar-two.component.scss"],
})
export class NavbarTwoComponent implements OnInit {
    cart = 0;
    lang = "en";
    Home: any;
    AboutUs: any;
    Instructor: any;
    Courses: any;
    Books: any;
    Blog: any;
    Eng: any;
    Ar: any;
    Login: any;
    SignUp: any;
    Profile: any;
    Logout: any;
    userStrsing: any;
    isAuthorized = false;
    BaseUrl = environment.BaseUrl;

    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private _cart: CartService,
        private defults: DefaultService,
        public translate: TranslateService
    ) {
        this.auth.currentUser();
        this.isAuthorized = this.auth.isAuthorized;
    }

    ngOnInit(): void {
        if (this.defults.getDefult().langId == 2) {
            this.lang = "ar";
            $("#lang").val("ar");
            this.translate.use("ar");
            document.dir = "rtl";
            document.body.style.direction = "rtl";
            $(".project-btn").attr("style", "left:0");
        } else {
            this.lang = "en";
            $("#lang").val("en");
            this.translate.use("en");
            document.dir = "ltr";

            $(".project-btn").attr("style", "right:0");
            document.body.style.direction = "ltr";
        }

        this.getLabels();
        this.cart = this._cart.getItems().length;

        var app = this;
        $(document).ready(function () {
            $(".selectLang").change(function () {
                app.useLanguage($(this).val());
            });
        });
    }
    getLabels() {
        this.translate
            .get([
                "Home",
                "AboutUs",
                "Instructor",
                "Courses",
                "Books",
                "Blog",
                "Eng",
                "Ar",
                "Login",
                "Signup",
                "Logout",
                "Profile",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.AboutUs = res.AboutUs;
                this.Instructor = res.Instructor;
                this.Courses = res.Courses;
                this.Books = res.Books;
                this.Blog = res.Blog;
                this.Eng = res.Eng;
                this.Ar = res.Ar;
                this.Login = res.Login;
                this.SignUp = res.Signup;
                this.Logout = res.Logout;
                this.Profile = res.Profile;
            });
    }
    ngAfterContentChecked() {
        // setTimeout(()=>{
        //    this.auth.currentUser()
        //    this.isAuthorized=this.auth.isAuthorized;
        //    this._cart.loadCart()
        //    var cartItems=this._cart.getItems()
        //    this.cart=cartItems.length

        //   },0)
        this.cart = this._cart.getItems().length;
        if (this.isAuthorized) {
            this.userStrsing = this.auth.currentUser();
        }
    }

    useLanguage(language: string): void {
        if (language == "en") {
            $("#lang").val("en");
            this.defults.setDefult(1);
        } else {
            $("#lang").val("ar");
            this.defults.setDefult(2);
        }

        location.reload();
    }

    logout() {
        this.auth.logout();
        this.auth.isAuthorized = false;
        this.isAuthorized = false;
        this.router.navigate(["/"]);
        location.reload();
    }
}
