import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/services/auth.service";
import { BookService } from "src/app/services/book.service";
import { CartService } from "src/app/services/cart.service";
import { DefaultService } from "src/app/services/default.service";
import { StudentService } from "src/app/services/student.service";
import { environment } from "src/environments/environment.prod";
declare var $: any;
@Component({
    selector: "app-mybooks",
    templateUrl: "./mybooks.component.html",
    styleUrls: ["./mybooks.component.scss"],
})
export class MybooksComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    change = false;
    filterSort = [
        {
            id: 1,
            value: "id",
            name: "Default sorting",
            type: "ASC",
        },

        {
            id: 2,
            value: "createdAt",
            name: "Sort by latest",
            type: "DESC",
        },

        {
            id: 3,
            value: "price",
            name: "Sort by price: low to high",
            type: "ASC",
        },

        {
            id: 4,
            value: "price",
            name: "Sort by price: high to low",
            type: "DESC",
        },

        {
            id: 5,
            value: "rate",
            name: "Sort by average rating",
            type: "DESC",
        },

        /*  {
        id: 6,
        value: 'no_student',
        name: 'Sort by popularity',
        type: 'DESC',
      },*/
    ];

    sort = null;
    msg = false;
    msgText = "No products were found";
    books = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    Home: any;
    BooksList: any;
    Showing: any;
    results: any;
    of: any;
    AddToCart: any;
    RemovefromCart: any;
    user: any = {};

    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private _book: BookService,
        private _student: StudentService,
        private _defult: DefaultService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();

        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
        } else {
            this.router.navigateByUrl("/");
        }

        this.getData();
        var app = this;
        $(document).ready(function () {
            $(".selectMyBooks").change(function () {
                app.getData($(this).val(), 2, 0);
            });
        });
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "BooksList",
                "Showing",
                "results",
                "of",
                "AddToCart",
                "RemovefromCart",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.BooksList = res.BooksList;
                this.Showing = res.Showing;
                this.results = res.results;
                this.of = res.of;
                this.AddToCart = res.AddToCart;
                this.RemovefromCart = res.RemovefromCart;
            });
    }

    changeOffset() {
        this.getData();
    }

    getData(sort?: any, limit?: any, offset?: any) {
        this.msg = false;
        var sortValue = null;
        var sortType = null;

        if (sort != null) {
            let sort2 = this.filterSort.find((e) => e.id == sort);
            sortValue = sort2.value;
            sortType = sort2.type;
        }

        let per_page = this.limit;
        let page = this.offset;

        if (limit != null) {
            per_page = limit;
        }

        if (offset != null) {
            page = offset;
        }

        if (this.user.type == "student") {
            this._student
                .getMyBooks(this.user.id, sortValue, sortType, per_page, page)
                .subscribe(
                    (res) => {
                        //  console.log(res)
                        // to check if more books exists
                        if (res.apiStatus) {
                            this.books = res.data;
                            if (this.books.length == 0) {
                                this.msg = true;
                            } else {
                                var count = res.count;
                                this.total_pages = Math.ceil(
                                    count / this.limit
                                );
                                this.number = Array.from(
                                    { length: this.total_pages },
                                    (v, k) => k + 1
                                );
                            }
                        }
                    },
                    (err) => {
                        this.msg = true;

                        //console.log(err)
                    },
                    () => {
                        //  console.log('sucess');
                    }
                );
        } else {
            this._book
                .getInstructorBooks(
                    this.user.id,
                    sortValue,
                    sortType,
                    per_page,
                    page
                )
                .subscribe(
                    (res) => {
                        //console.log(res)
                        //console.log("inst", res);
                        // to check if more courses exists
                        if (res.apiStatus) {
                            this.books = res.data;
                            if (this.books.length == 0) {
                                this.msg = true;
                            } else {
                                var count = res.count;
                                this.total_pages = Math.ceil(
                                    count / this.limit
                                );
                                this.number = Array.from(
                                    { length: this.total_pages },
                                    (v, k) => k + 1
                                );
                            }
                        }
                    },
                    (err) => {
                        this.msg = true;
                        //console.log(err);
                    },
                    () => {
                        //console.log("sucess");
                    }
                );
        }
    }

    changeStart(item?: any, star?: any) {
        this._book.rateBook(this.user.id, item.id, star).subscribe(
            (res) => {
                item.rate_1 = 0;
                item.rate_2 = 0;
                item.rate_3 = 0;
                item.rate_4 = 0;
                item.rate_5 = 0;

                item["rate_" + star] = 1;
            },
            (err) => {},
            () => {
                //  console.log('sucess');
            }
        );
    }
}
