import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class StudentService {
    constructor(private _http: HttpClient) {}

    loginStudent(email: string, password: string) {
        return this._http.post<any>(environment.BaseUrl + "/student/login/", {
            email: email,
            password: password,
        });
    }
    registerStudent(
        firstName: string,
        lastName: string,
        email: string,
        password: string
    ) {
        return this._http.post<any>(
            environment.BaseUrl + "/student/register/",
            {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
            }
        );
    }
    updateProfile(
        id?: any,
        firstName?: any,
        lastName?: any,
        email?: any,
        phoneNumber?: any
    ) {
        return this._http.post<any>(
            environment.BaseUrl + `/student/updateUser`,
            {
                id: id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
            }
        );
    }

    resetPassword(token, pass) {
        return this._http.post<any>(
            environment.BaseUrl + "/student/resetPassword/",
            {
                token,
                pass,
            }
        );
    }

    forgetPassword(email, base_url) {
        return this._http.post<any>(
            environment.BaseUrl + "/student/forgotPassword/",
            {
                email,
                base_url,
            }
        );
    }

    changePassword(oldpassword, newpass, email) {
        return this._http.post<any>(
            environment.BaseUrl + "/student/changePassword/",
            { oldpassword, newpass, email }
        );
    }

    uploadProfilePhoto(image, email) {
        let formData = new FormData();
        formData.append("image", image);
        formData.append("email", email);
        return this._http.post<any>(
            environment.BaseUrl + "/student/uploadProfileImage",
            formData
        );
    }

    getMyCourses(
        userId?: any,
        sortType?: any,
        sortCol?: any,
        start?: any,
        per_page?: any
    ) {
        if (!sortCol) {
            sortCol = null;
            sortType = "";
        }
        return this._http.post<any>(
            environment.BaseUrl + `/student/getMyCourses/${userId}`,
            {
                sortType: sortType,
                sortCol: sortCol,
                start: start,
                per_page: per_page,
            }
        );
    }

    getMyBooks(
        userId?: any,
        sortType?: any,
        sortCol?: any,
        start?: any,
        per_page?: any
    ) {
        if (!sortCol) {
            sortCol = null;
            sortType = "";
        }
        return this._http.post<any>(
            environment.BaseUrl + `/student/getMyBooks/${userId}`,
            {
                sortType: sortType,
                sortCol: sortCol,
                start: start,
                per_page: per_page,
            }
        );
    }
}
