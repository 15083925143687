import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { DefaultService } from "src/app/services/default.service";
declare var $: any;
@Component({
    selector: "app-change-password",
    templateUrl: "./changePassword.component.html",
    styleUrls: ["./changePassword.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
    key: any[] = [];
    email: string;
    LoginwithFacebook: any;
    LoginwithLinkedin: any;
    Yourpassword: any;
    LogintoSalesH2O: any;
    LoginwithGoogle: any;
    Youremail: any;
    Forgotpassword: any;
    Signup: any;
    WhatsMyPassword: any;
    forgetpasmessage: any;
    Cancel: any;
    Newtosalesh2o: any;
    copyRight: any;
    Or: any;
    LoginASInstuctor: any;
    points: 0;
    token: any = null;
    msg = null;
    error: any = "no";

    Home: any;
    lang = "en";
    Login: any;

    Email: any;
    Password: any;
    ResetPassword: any;
    ForgetYourPassword: any;
    PleaseWriteYourEmail: any;
    PleaseCheckYourEmail: any;
    Confirm: any;
    yourpasswordwaschanged: any;

    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private student: StudentService,
        private activeRouter: ActivatedRoute,
        public translate: TranslateService,
        private _defult: DefaultService
    ) {
        if (this.auth.currentUser()) {
            let user = this.auth.currentUser();
            this.email = user.email;
        }
        let app = this;
        $(document).keypress(function (e) {
            if (e.which == 13) {
                app.confirm2($("#oldpass"), $("#pass"), $("#conpass"));
            }
        });
    }

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "Login",
                "Email",
                "Password",
                "ForgetYourPassword",
                "ResetPassword",
                "PleaseWriteYourEmail",
                "PleaseCheckYourEmail",
                "Confirm",
                "yourpasswordwaschanged",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.Login = res.Login;
                this.Email = res.Email;
                this.Password = res.Password;
                this.ForgetYourPassword = res.ForgetYourPassword;
                this.ResetPassword = res.ResetPassword;
                this.Confirm = res.Confirm;
                this.PleaseWriteYourEmail = res.PleaseWriteYourEmail;
                this.PleaseCheckYourEmail = res.PleaseCheckYourEmail;
                this.yourpasswordwaschanged = res.yourpasswordwaschanged;
            });
    }

    // getLabels() {
    //     this.translate
    //         .get([
    //             "LoginwithFacebook",
    //             "LoginwithLinkedin",
    //             "Yourpassword",
    //             "LogintoSalesH2O",
    //             "LoginwithGoogle",
    //             "Youremail",
    //             "Forgotpassword",
    //             "Signup",
    //             "WhatsMyPassword",
    //             "forgetpasmessage",
    //             "Cancel",
    //             "Newtosalesh2o",
    //             "copyRight",
    //             "Or",
    //             "LoginASInstuctor",
    //         ])
    //         .subscribe((res) => {
    //             this.LoginwithFacebook = res.LoginwithFacebook;
    //             this.LoginwithLinkedin = res.LoginwithLinkedin;
    //             this.Yourpassword = res.Yourpassword;
    //             this.LogintoSalesH2O = res.LogintoSalesH2O;
    //             this.LoginwithGoogle = res.LoginwithGoogle;
    //             this.Youremail = res.Youremail;
    //             this.Forgotpassword = res.Forgotpassword;
    //             this.Signup = res.Signup;
    //             this.WhatsMyPassword = res.WhatsMyPassword;
    //             this.forgetpasmessage = res.forgetpasmessage;
    //             this.Cancel = res.Cancel;
    //             this.Newtosalesh2o = res.Newtosalesh2o;
    //             this.copyRight = res.copyright;
    //             this.Or = res.or;
    //             this.LoginASInstuctor = res.LoginASInstuctor;
    //         });
    // }

    ngOnDestroy() {
        /* let hh = <HTMLFormElement>document.getElementById('header');
    let ff = <HTMLFormElement>document.getElementById('footer');
    hh.style.display = 'block';
    ff.style.display = 'block';*/
    }

    isLoading: boolean = false;

    confirm(oldpass, pass, conpass) {
        let app = this;
        let num = 0;

        if (oldpass.value.length < 6) {
            oldpass.style.border = "1px solid red";
            oldpass.parentNode.querySelector("#error3").innerHTML =
                "Password Is Required ";
            num++;
        } else {
            oldpass.parentNode.style.border = "none";
            oldpass.parentNode.querySelector("#error3").innerHTML = "";
        }

        if (pass.value.length < 6) {
            pass.style.border = "1px solid red";
            pass.parentNode.querySelector("#error").innerHTML =
                "Password Is Required ";
            num++;
        } else {
            pass.parentNode.style.border = "none";
            pass.parentNode.querySelector("#error").innerHTML = "";
        }

        if (conpass.value.length !== pass.value.length) {
            conpass.style.border = "1px solid red";
            conpass.parentNode.querySelector("#error2").innerHTML =
                "Connfirm password must be equal Password ";
            num++;
        } else {
            conpass.parentNode.style.border = "none";
            conpass.parentNode.querySelector("#error2").innerHTML = "";
        }

        if (num != 0) {
            return;
        } else {
            //   this.spinner.show();
            this.student
                .changePassword(oldpass.value, pass.value, this.email)
                .subscribe(
                    (res) => {
                        if (res.error == 0) {
                            this.msg = "Your Password Changed";
                            this.error = false;
                            oldpass.value = "";
                            pass.value = "";
                            conpass.value = "";
                        } else {
                            this.msg = res.message;
                            this.error = true;
                        }
                    },
                    (err) => {
                        this.msg =
                            "Cant change your password and check your old password";
                        this.error = false;
                    }
                );
        }
    }

    confirm2(oldpass, pass, conpass) {
        let app = this;
        let num = 0;

        if (oldpass.val().length < 6) {
            oldpass.css({
                border: "1px solid red",
            });
            $("#error3").html("Password Is Required ");
            num++;
        } else {
            oldpass.css({ border: "none" });
            $("#error3").html("");
        }

        if (pass.val().length < 6) {
            pass.css({
                border: "1px solid red",
            });
            $("#error").html("Password Is Required ");
            num++;
        } else {
            pass.css({ border: "none" });
            $("#error").html("");
        }

        if (conpass.val() !== pass.val()) {
            conpass.css({
                border: "1px solid red",
            });
            $("#error2").html("Confirm password must be equal Password ");
            num++;
        } else {
            conpass.css({ border: "none" });
            $("#error2").html("");
        }

        if (num != 0) {
            return;
        } else {
            //   this.spinner.show();
            this.student
                .changePassword(oldpass.val(), pass.val(), this.email)
                .subscribe(
                    (res) => {
                        if (res.error == 0) {
                            this.msg = "Your Password Changed";
                            this.error = true;
                            oldpass.val("");
                            pass.val("");
                            conpass.val("");
                        } else {
                            this.msg = res.message;
                            this.error = false;
                        }
                    },
                    (err) => {
                        this.error = false;
                        this.msg =
                            "Cant change your password and check your old password";
                    }
                );
        }
    }
}
