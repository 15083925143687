import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";

import { DefaultService } from "src/app/services/default.service";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;
@Component({
    selector: "app-login",
    templateUrl: "./resetPassword.component.html",
    styleUrls: ["./resetPassword.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
    key: any[] = [];

    LoginwithFacebook: any;
    LoginwithLinkedin: any;
    Yourpassword: any;
    LogintoSalesH2O: any;
    LoginwithGoogle: any;
    Youremail: any;
    Forgotpassword: any;
    Signup: any;
    WhatsMyPassword: any;
    forgetpasmessage: any;
    Cancel: any;
    Newtosalesh2o: any;
    copyRight: any;
    Or: any;
    LoginASInstuctor: any;
    points: 0;
    token: any = null;
    msg = null;
    error = false;

    lang = "en";
    Home: any;

    Login: any;

    Email: any;
    Password: any;
    ResetPassword: any;
    ForgetYourPassword: any;
    PleaseWriteTheNewPassword: any;
    Thelinkhasexpiredclickon: any;
    yourpasswordwaschangedyoucan: any;
    Confirm: any;

    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private student: StudentService,
        private activeRouter: ActivatedRoute,

        public translate: TranslateService,
        private _defult: DefaultService
    ) {
        if (auth.currentUser()) {
            this.router.navigateByUrl("/");
        }
        let app = this;
        $(document).keypress(function (e) {
            if (e.which == 13) {
                app.confirm2($("#pass"), $("#conpass"));
            }
        });

        this.activeRouter.params.subscribe((params) => {
            this.token = params["token"];
        });
    }

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "Login",
                "Email",
                "Password",
                "ForgetYourPassword",
                "ResetPassword",
                "PleaseWriteTheNewPassword",
                "yourpasswordwaschangedyoucan",
                "Thelinkhasexpiredclickon",
                "Confirm",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.Login = res.Login;
                this.Email = res.Email;
                this.Password = res.Password;
                this.ForgetYourPassword = res.ForgetYourPassword;
                this.ResetPassword = res.ResetPassword;
                this.PleaseWriteTheNewPassword = res.PleaseWriteTheNewPassword;
                this.yourpasswordwaschangedyoucan =
                    res.yourpasswordwaschangedyoucan;
                this.Thelinkhasexpiredclickon = res.Thelinkhasexpiredclickon;
                this.Confirm = res.Confirm;
            });
    }

    ngOnDestroy() {
        /* let hh = <HTMLFormElement>document.getElementById('header');
    let ff = <HTMLFormElement>document.getElementById('footer');
    hh.style.display = 'block';
    ff.style.display = 'block';*/
    }

    validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    isLoading: boolean = false;

    confirm(pass, conpass) {
        let app = this;
        let num = 0;

        if (pass.value.length < 6) {
            pass.style.border = "1px solid red";
            pass.parentNode.querySelector("#error").innerHTML =
                "Password Is Required ";
            num++;
        } else {
            pass.parentNode.style.border = "none";
            pass.parentNode.querySelector("#error").innerHTML = "";
        }

        if (conpass.value.length !== pass.value.length) {
            conpass.style.border = "1px solid red";
            conpass.parentNode.querySelector("#error2").innerHTML =
                "Connfirm password must be equal Password ";
            num++;
        } else {
            conpass.parentNode.style.border = "none";
            conpass.parentNode.querySelector("#error2").innerHTML = "";
        }

        if (num != 0) {
            return;
        } else {
            //   this.spinner.show();
            this.student.resetPassword(this.token, pass.value).subscribe(
                (res) => {
                    if (res.error == 0) {
                        this.msg = "Your Password Changed";

                        this.error = true;
                    } else {
                        this.msg = "The link has expired, try again";

                        this.error = false;
                    }
                },
                (err) => {
                    this.msg = "Cant change your password";

                    this.error = false;
                }
            );
        }
    }

    confirm2(pass, conpass) {
        let app = this;
        let num = 0;

        if (pass.val().length < 6) {
            pass.css({
                border: "1px solid red",
            });
            $("#error").html("Password Is Required ");
            num++;
        } else {
            pass.css({ border: "none" });
            $("#error").html("");
        }

        if (conpass.val() !== pass.val()) {
            conpass.css({
                border: "1px solid red",
            });
            $("#error2").html("Confirm password must be equal Password ");
            num++;
        } else {
            conpass.css({ border: "none" });
            $("#error2").html("");
        }

        if (num != 0) {
            return;
        } else {
            //   this.spinner.show();
            this.student.resetPassword(this.token, pass.val()).subscribe(
                (res) => {
                    if (res.error == 0) {
                        this.msg = "Please check your email";

                        this.error = true;
                    } else {
                        this.msg = "The link has expired, try again";

                        this.error = false;
                    }
                },
                (err) => {
                    this.msg = "Cant change your password";

                    this.error = false;
                }
            );
        }
    }
}
