import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";

@Injectable({
    providedIn: "root",
})
export class InstructorService {
    constructor(private _http: HttpClient) {}
    getAllInstructors(
        search?: any,
        sortCol?: any,
        sortType?: any,
        start?: any,
        per_page?: any
    ) {
        if (!sortCol) {
            sortCol = null;
            sortType = "";
        }

        return this._http.post<any>(
            environment.BaseUrl + "/instructor/getAllInstractors",
            {
                search: search,
                sortCol: sortCol,
                sortType: sortType,
                start: start,
                per_page: per_page,
            }
        );
    }
    getOneInstructor(id?: any) {
        return this._http.post<any>(
            environment.BaseUrl + `/instructor/getOne`,
            { id }
        );
    }

    loginInstructor(email: string, password: string) {
        return this._http.post<any>(
            environment.BaseUrl + "/instructor/login/",
            { email: email, password: password }
        );
    }
    registerInstructor(
        firstName: string,
        lastName: string,
        email: string,
        password: string
    ) {
        return this._http.post<any>(
            environment.BaseUrl + "/instructor/register/",
            {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
            }
        );
    }

    updateProfile(
        id?: any,
        firstName?: any,
        lastName?: any,
        email?: any,
        phoneNumber?: any
    ) {
        return this._http.put<any>(environment.BaseUrl + `/instructor/${id}`, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
        });
    }

    uploadProfilePhoto(image, email) {
        let formData = new FormData();
        formData.append("image", image);
        formData.append("email", email);
        return this._http.post<any>(
            environment.BaseUrl + "/instructor/uploadProfileImage",
            formData
        );
    }
}
