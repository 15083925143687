import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthenticationService } from "src/app/services/auth.service";
import { CookieService } from "src/app/services/cookie.service";
import { DefultService } from "src/app/services/defult/defult.service";
import { environment } from "src/environments/environment.prod";

@Component({
    selector: "app-affiliate",
    templateUrl: "./affiliate.component.html",
    styleUrls: ["./affiliate.component.scss"],
})
export class AffiliateComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    user: any = null;
    base_url: string;
    afilActive: string;
    Affiliate = null;
    Home = null;
    constructor(
        private router: ActivatedRoute,
        private routerNavigation: Router,
        private _defult: DefultService,
        private _http: HttpClient,
        private cook: CookieService,
        private auth: AuthenticationService
    ) {
        this.user = this.auth.currentUser();
        this.afilActive = this.cook.getCookie("Affilliate");

        this.base_url = window.location.origin;
    }
    ///get-afil
    affilliate: any;
    ngOnInit(): void {
        if (this.afilActive) {
            (<HTMLInputElement>document.getElementById("afil")).value =
                this.afilActive;
            //console.log(this.afilActive);
        }
        this._http
            .post<any>(environment.BaseUrl + `/afil/get-afil`, {})
            .subscribe((res) => {
                this.affilliate = res.data;
                // console.log(res);
            });
    }
    affilliateURL: any;
    createLink() {
        if (this.user) {
            this._http
                .post<any>(environment.BaseUrl + `/afil/Create-afilink`, {
                    owner: this.user.id,
                })
                .subscribe((res) => {
                    this.affilliateURL = res.URL;
                    (<HTMLInputElement>document.getElementById("afil")).value =
                        this.base_url + this.affilliateURL;
                    this.cook.setCookie(
                        "Affilliate",
                        this.base_url + this.affilliateURL,
                        1
                    );
                    //console.log(res);
                });
        } else {
            this.routerNavigation.navigateByUrl("/login");
        }
    }
}
