<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ Login }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ Login }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>{{ WelcomeBack }}!</h3>
                <p>{{ Pleaselogintoyouraccount }}</p>
                <p>BETA VERSION, You CAN'T Login</p>
            </div>

            <form method="post" id="contactform" autocomplete="off" [formGroup]="loginForm">
                <div class="alert alert-danger" *ngIf="msg != null">
                    {{ msg }}
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="email" class="form-control" [placeholder]="[Email]" formControlName="email" />
                            <div *ngIf="
                                    email?.invalid &&
                                    (email?.touched || email?.dirty)
                                ">
                                <div *ngIf="email?.errors?.required" class="text-danger">
                                    {{ required }}
                                </div>
                                <div *ngIf="email?.errors?.email" class="text-danger">
                                    {{ youmustentervalidemail }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="password" class="form-control" [placeholder]="[Password]" formControlName="password" />
                            <div *ngIf="
                                    password?.invalid &&
                                    (password.touched || password?.dirty)
                                ">
                                <div *ngIf="password?.errors?.required" class="text-danger">
                                    {{ required }}
                                </div>
                                <div *ngIf="password?.errors?.minLength" class="text-danger">
                                    {{ passwordlenghtmustbe6atleast }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme">
                            <label class="form-check-label" for="checkme">{{KeepmeLogin}}</label>
                        </div>
                    </div>

                    <div class="col-lg-12 text-end">
                        <p class="forgot-password">
                            <a routerLink="/">{{ForgotPassword}}?</a>
                        </p>
                    </div> -->

                    <div class="col-lg-12">
                        <div class="send-btn">
                            <button type="submit" class="default-btn-one" style="margin-left: 20px">
                               Login Now
                            </button>
                           
                        </div>

                        <br>
                        <a style='margin-bottom:15px !important;' routerLink="/" class="form_under_router">{{ForgetYourPassword}}</a>


                        <br> {{ Donthaveaccount }}?
                        <a routerLink="/signup">Signup!</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>