<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ BooksList }}</h2>

                    <ul>
                        <li>
                            <a routerLink="/">{{ Home }}</a>
                        </li>
                        <li>{{ BooksList }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="shop-area ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6">
                    <div class="woocommerce-result-count">
                        <p>
                            {{ Showing }} {{ (offset - 1) * limit + 1 }}-{{ (offset - 1) * limit + 1 + limit }} {{ of }} {{ total_pages }} {{ results }}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6">
                    <div class="woocommerce-topbar-ordering">
                        <select class="selectbook">
                            <option
                                *ngFor="let item of filterSort"
                                [value]="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row text-center alert alert-danger" *ngIf="msg">
            {{ msgText }}
        </div>
        <div class="row" *ngIf="books.length > 0">
            <div class="col-lg-4 col-md-8 col-sm-12 mx-md-auto mx-lg-0" *ngFor="let book of books">
                <div class="single-product-box">
                    <div class="product-image">
                        <a [routerLink]="['/book-details/' + book.id]" class="d-block"><img src="{{ baseUrl + '/' + book.image }}" alt="image" /></a>

                        <a *ngIf="!isBookExist(book)" (click)="addToCart(book)" class="add-to-cart-btn">{{ AddToCart }}
                            <i class="flaticon-shopping-cart"></i
                        ></a>
                        <a *ngIf="isBookExist(book)" (click)="removeFromCart(book)" class="add-to-cart-btn">{{ RemovefromCart
                            }}<i class="flaticon-shopping-cart"></i
                        ></a>
                    </div>

                    <div class="product-content">
                        <h3>
                            <a [routerLink]="['/book-details/' + book.id]">{{
                                book[lang + "_name"]
                            }}</a>
                        </h3>

                        <div class="price">
                            <span class="new" *ngIf="book.discount > 0">{{ book.price_unit }}
                                {{ book.discount}}</span
                            >
                            <span
                                class="old"
                                style="
                                    text-decoration: line-through;
                                    color: grey;
                                    margin-left: 10px;
                                "
                                *ngIf="book.discount > 0"
                                >{{ book.price_unit }} {{ book.price }}</span
                            >
                            <span class="new" *ngIf="book.discount == 0"
                                >{{ book.price_unit }} {{ book.price }}</span
                            >
                        </div>

                        <div class="rating">
                            <i
                                [class]="
                                    0 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                [class]="
                                    1 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                [class]="
                                    2 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                [class]="
                                    3 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                            <i
                                [class]="
                                    4 < book.rate && book.rate <= 5
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                "
                            ></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a
                        class="prev page-numbers"
                        class="{{ offset <= 0 ? 'disable' : 'enable' }}"
                        (click)="offset = offset - 1; changeOffset()"
                        ><i class="fas fa-angle-double-left"></i
                    ></a>
                    <a
                        aria-current="page"
                        class="page-numbers {{ i == offset ? 'current' : '' }}"
                        *ngFor="let num of number; let i = index"
                        (click)="offset = i; changeOffset()"
                        >{{ i + 1 }}</a
                    >
                    <a
                        class="next page-numbers"
                        class="{{
                            offset >= total_pages - 1 ? 'disable' : 'enable'
                        }}"
                        (click)="offset = offset + 1; changeOffset()"
                        ><i class="fas fa-angle-double-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="productive-section ptb-100">
    <div class="container">
        <div class="productive-title">
            <span>Why Our services</span>
                            <h3>Our Productive Services is Ready</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="productive-area-content">
                                    <div class="icon">
                                        <i class="flaticon-headphones"></i>
                                    </div>
                                    <h3>Customer Assistance</h3>
                                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="productive-area-content">
                                    <div class="icon">
                                        <i class="flaticon-tag"></i>
                                    </div>
                                    <h3>Affordable Prices</h3>
                                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="productive-area-content">
                                    <div class="icon">
                                        <i class="flaticon-cyber-security"></i>
                                    </div>
                                    <h3>Global Presence</h3>
                                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="productive-area-content">
                                    <div class="icon">
                                        <i class="flaticon-innovation"></i>
                                    </div>
                                    <h3>Innovative Ideas</h3>
                                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="productive-area-content">
                                    <div class="icon">
                                        <i class="flaticon-content"></i>
                                    </div>
                                    <h3>Content Marketing</h3>
                                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="productive-area-content">
                                    <div class="icon">
                                        <i class="flaticon-unlink"></i>
                                    </div>
                                    <h3>Pro Building Services</h3>
                                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                                </div>
                            </div>
                        </div>
                    </div>
</section>

<section class="needs-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="needs-area-content">
                    <span>What We do</span>
                    <h3>Your business agency needs a goal Our agency helps to achieve in way</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia.</p>

                    <div class="needs-text">
                        <p><b>8.93</b> (Loremips) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum dolor si Ut enim ad minim venia.</p>
                    </div>

                    <div class="needs-text">
                        <p><b>1.9</b> (importent) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum dolor si Ut enim ad minim venia.</p>
                    </div>

                    <div class="needs-btn">
                        <a routerLink="/" class="needs-btn-one">Discover More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="needs">
                    <img src="assets/img/needs.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-section pb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item" target="_blank" class="d-block">
                <a href="#">
                    <img src="assets/img/partnar/partner-img1.png" alt="partner">
                </a>
            </div>

            <div class="partner-item" target="_blank" class="d-block">
                <a href="#">
                    <img src="assets/img/partnar/partner-img2.png" alt="partner">
                </a>
            </div>

            <div class="partner-item" target="_blank" class="d-block">
                <a href="#">
                    <img src="assets/img/partnar/partner-img3.png" alt="partner">
                </a>
            </div>

            <div class="partner-item" target="_blank" class="d-block">
                <a href="#">
                    <img src="assets/img/partnar/partner-img4.png" alt="partner">
                </a>
            </div>

            <div class="partner-item" target="_blank" class="d-block">
                <a href="#">
                    <img src="assets/img/partnar/partner-img5.png" alt="partner">
                </a>
            </div>
        </div>
    </div>
</div>

<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>

        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section>-->