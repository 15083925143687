import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/pages/home/home.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { CourseDetailsComponent } from "./components/pages/course-details/course-details.component";
import { CoursesListComponent } from "./components/pages/courses-list/courses-list.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { BookComponent } from "./components/pages/book/book.component";
import { BookDetailsComponent } from "./components/pages/book-details/book-details.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ProfileComponent } from "./components/pages/profile/profile.component";
import { ShowMyCoursesComponent } from "./components/pages/show-my-courses/show-my-courses.component";
import { InstructorCoursesComponent } from "./components/pages/instructor-courses/instructor-courses.component";
import { AffiliateComponent } from "./components/pages/affiliate/affiliate.component";
import { PrivacyComponent } from "./components/pages/privacy/privacy.component";
import { ForgetPasswordComponent } from "./components/pages/forgetPassword/forgetPassword.component";
import { ResetPasswordComponent } from "./components/pages/ResetPassword/resetPassword.component";

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "about", component: AboutComponent },
    { path: "books", component: BookComponent },
    { path: "book-details/:id", component: BookDetailsComponent },
    { path: "team", component: TeamComponent },
    { path: "faq", component: FaqComponent },
    { path: "login", component: LoginComponent },
    { path: "signup", component: SignupComponent },
    { path: "forgetPassword", component: ForgetPasswordComponent },
    { path: "resetPassword/:token", component: ResetPasswordComponent },

    { path: "error", component: ErrorComponent },
    { path: "courses-list", component: CoursesListComponent },
    { path: "courses-details/:id", component: CourseDetailsComponent },
    {
        path: "courses-details-instructor/:id",
        component: InstructorCoursesComponent,
    },
    { path: "show-my-courses/:id", component: ShowMyCoursesComponent },
    { path: "cart", component: CartComponent },
    { path: "checkout/:from", component: CheckoutComponent },
    { path: "blog", component: BlogComponent },
    { path: "blog-details/:id", component: BlogDetailsComponent },
    { path: "profile", component: ProfileComponent },
    { path: "contact", component: ContactComponent },
    { path: "affiliate", component: AffiliateComponent },
    { path: "privacy", component: PrivacyComponent },
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
