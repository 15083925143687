<app-preloader></app-preloader>
<app-navbar-two></app-navbar-two>


<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{Affiliate}}</h2>

                    <ul>
                        <li><a routerLink="/">{{Home}}</a></li>
                        <li>{{Affiliate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 style="margin-top: 30px">Langely Affilliate Program</h1>
            </div>
            <div class="col-md-12">
                <div class="cardaff">
                    <div style="float: left">
                        <h6>
                            Get easy Money$$ By Sharing Your Affilliate Link!
                        </h6>
                        <p>
                            Commission for each element in this site, unlimited times for purchasing
                        </p>
                        <h4>Commission Details</h4>
                        <div>
                            <h6>For Courses:</h6>
                            <p>{{ affilliate?.courseComm }}%</p>
                            <h6>For Book:</h6>
                            <p>{{ affilliate?.bookComm }}%</p>

                            <h6 style="color: blueviolet">
                                Started at: {{ affilliate?.start_at | date }}
                            </h6>
                            <h6 style="color: green">
                                Ends at: {{ affilliate?.end_at | date }}
                            </h6>
                        </div>
                    </div>

                    <img src="assets/img/affl.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 sdfsdf">
                <a href="">invite</a>
                <a href="">invite Hestory</a>
            </div>
        </div>
    </div>
</section> -->

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="card jksfsjkf">
                    <h6>Invite friends via link</h6>
                    <input type="text" name="afil" id="afil" placeholder="Copy and Share your link
                    " value="Hey! I’m using langely to learn anywhere in the world, and you can too! https://langely.com/" />
                    <div>
                        <div class="afjsdknf">
                            <button (click)="createLink()">Create Link</button>
                            <div class="sdfjksdf">
                                <button>
                                    <i class="fab fa-facebook-f"></i>
                                </button>

                                <button>
                                    <i class="fab fa-twitter"></i>
                                </button>

                                <button>
                                    <i class="fab fa-linkedin-in"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section>
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="card jksfsjkf">
                    <h6>Invite business via email</h6>
                    <input
                        type="email"
                        placeholder="email
                    "
                        value=""
                    />
                    <div>
                        <div class="afjsdknf">
                            <button>invite</button>
                            <p>
                                Press enter or type comma to invite multiple
                                email addresses
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card jksfsjkf">
                    <h6>How it works</h6>
                    <ul>
                        <li>1. Invite a business to check out SalesH2O.</li>
                        <li>
                            2. They’ll need to book a call with our team via
                            your referral link.
                        </li>
                        <li>
                            3. As soon as they’ve paid either four contractors
                            or one employee, you’ll receive your $200 Amazon
                            gift card!
                        </li>
                        <li>
                            3. As soon as they’ve paid either four contractors
                            or one employee, you’ll receive your $200 Amazon
                            gift card!
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->