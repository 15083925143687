import { Component, OnInit } from "@angular/core";
import { CartService } from "src/app/services/cart.service";
import { BookService } from "src/app/services/book.service";
import { environment } from "src/environments/environment.prod";
import { DefaultService } from "src/app/services/default.service";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;
@Component({
    selector: "app-book",
    templateUrl: "./book.component.html",
    styleUrls: ["./book.component.scss"],
})
export class BookComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    change = false;
    filterSort = [
        {
            id: 1,
            value: "id",
            name: "Default sorting",
            type: "ASC",
        },

        {
            id: 2,
            value: "createdAt",
            name: "Sort by latest",
            type: "DESC",
        },

        {
            id: 3,
            value: "price",
            name: "Sort by price: low to high",
            type: "ASC",
        },

        {
            id: 4,
            value: "price",
            name: "Sort by price: high to low",
            type: "DESC",
        },

        {
            id: 5,
            value: "rate",
            name: "Sort by average rating",
            type: "DESC",
        },

        /*  {
        id: 6,
        value: 'no_student',
        name: 'Sort by popularity',
        type: 'DESC',
      },*/
    ];

    sort = null;
    msg = false;
    msgText = "No products were found";
    books = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    Home: any;
    BooksList: any;
    Showing: any;
    results: any;
    of: any;
    AddToCart: any;
    RemovefromCart: any;

    constructor(
        private _book: BookService,
        private _cart: CartService,
        private _defult: DefaultService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();

        this.getData();
        var app = this;
        $(document).ready(function () {
            $(".selectbook").change(function () {
                app.getData($(this).val(), 2, 0);
            });
        });
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "BooksList",
                "Showing",
                "results",
                "of",
                "AddToCart",
                "RemovefromCart",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.BooksList = res.BooksList;
                this.Showing = res.Showing;
                this.results = res.results;
                this.of = res.of;
                this.AddToCart = res.AddToCart;
                this.RemovefromCart = res.RemovefromCart;
            });
    }

    changeOffset() {
        this.getData();
    }

    getData(sort?: any, limit?: any, offset?: any) {
        this.msg = false;
        var sortValue = null;
        var sortType = null;

        if (sort != null) {
            let sort2 = this.filterSort.find((e) => e.id == sort);
            sortValue = sort2.value;
            sortType = sort2.type;
        }

        let per_page = this.limit;
        let page = this.offset;

        if (limit != null) {
            per_page = limit;
        }

        if (offset != null) {
            page = offset;
        }

        this._book
            .getAllBooks(null, "", sortValue, sortType, per_page, page)
            .subscribe(
                (res) => {
                    //console.log(res);
                    // to check if more books exists
                    if (res.apiStatus) {
                        this.books = res.data;
                        if (this.books.length == 0) {
                            this.msg = true;
                        } else {
                            var count = res.count;
                            this.total_pages = Math.ceil(count / this.limit);
                            this.number = Array.from(
                                { length: this.total_pages },
                                (v, k) => k + 1
                            );
                        }
                    }
                },
                (err) => {
                    this.msg = true;

                    //console.log(err);
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }
    //check if item exists
    isBookExist(book) {
        if (this._cart.itemInCart(book)) {
            return true;
        } else {
            return false;
        }
    }

    //add to cart
    addToCart(book) {
        if (!this._cart.itemInCart(book)) {
            this._cart.addToCart(book); //add items in cart
        }
    }

    // remove book from cart
    removeFromCart(book) {
        if (this._cart.itemInCart(book)) {
            this._cart.removeItem(book); //add items in cart
        }
    }
}
