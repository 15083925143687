import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";
import { HttpClient } from "@angular/common/http";
@Injectable({
    providedIn: "root",
})
export class CartService {
    cartItems: any = [];
    cartItems2: any = [];

    constructor(private _http: HttpClient) {
        this.loadCart();
        this.loadCart2();
    }
    addToCart(addedItem) {
        this.cartItems.push(addedItem);
        this.saveCart();
    }

    addToCart2(addedItem) {
        this.cartItems2.push(addedItem);
        this.saveCart2();
    }

    loadCart() {
        this.cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    }

    loadCart2() {
        this.cartItems2 = JSON.parse(localStorage.getItem("cart2")) || [];
    }

    saveCart() {
        localStorage.setItem("cart", JSON.stringify(this.cartItems));
    }

    saveCart2() {
        localStorage.setItem("cart2", JSON.stringify(this.cartItems2));
    }

    getItems() {
        return this.cartItems;
    }

    getItems2() {
        return this.cartItems2;
    }

    clearCart() {
        this.cartItems = [];

        localStorage.removeItem("cart");
    }

    clearCart2() {
        this.cartItems2 = [];

        localStorage.removeItem("cart2");
    }

    removeItem(item) {
        const index = this.cartItems.findIndex(
            (o) => o.id === item.id && o.cat_id == item.cat_id
        );

        if (index > -1) {
            this.cartItems.splice(index, 1);
            this.saveCart();
        }
    }

    removeItem2(item) {
        const index = this.cartItems2.findIndex(
            (o) => o.id === item.id && o.cat_id == item.cat_id
        );

        if (index > -1) {
            this.cartItems2.splice(index, 1);
            this.saveCart2();
        }
    }

    itemInCart(item): boolean {
        return (
            this.cartItems.findIndex(
                (o) => o.id === item.id && o.cat_id == item.cat_id
            ) > -1
        );
    }

    itemInCart2(item): boolean {
        return (
            this.cartItems2.findIndex(
                (o) => o.id === item.id && o.cat_id == item.cat_id
            ) > -1
        );
    }

    createOne(
        userId?: string,
        courseId?: any,
        before_price?: any,
        after_price?: any,
        type?: any,
        order_id?: any,
        email?: any,
        name?: any
    ) {
        return this._http.post<any>(environment.BaseUrl + "/cart/", {
            userId,
            courseId,
            before_price,
            after_price,
            type,
            order_id,
            email,
            name,
        });
    }

    checkItems(item, userId) {
        return this._http.post<any>(environment.BaseUrl + "/cart/checkItems/", {
            item,
            userId,
        });
    }
}
