import { Component, OnInit } from "@angular/core";
import { CartService } from "src/app/services/cart.service";
import { CourseService } from "src/app/services/course.service";
import { DefaultService } from "src/app/services/default.service";
import { environment } from "src/environments/environment.prod";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { OrderService } from "src/app/services/order.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth.service";
declare var $: any;

@Component({
    selector: "app-courses-list",
    templateUrl: "./courses-list.component.html",
    styleUrls: ["./courses-list.component.scss"],
})
export class CoursesListComponent implements OnInit {
    baseUrl: string = environment.BaseUrl;
    change = false;
    filterSort = [
        {
            id: 1,
            value: "id",
            name: "Default sorting",
            type: "ASC",
        },

        {
            id: 2,
            value: "createdAt",
            name: "Sort by latest",
            type: "DESC",
        },

        {
            id: 3,
            value: "price",
            name: "Sort by price: low to high",
            type: "ASC",
        },

        {
            id: 4,
            value: "price",
            name: "Sort by price: high to low",
            type: "DESC",
        },

        {
            id: 5,
            value: "rate",
            name: "Sort by average rating",
            type: "DESC",
        },

        {
            id: 6,
            value: "no_student",
            name: "Sort by popularity",
            type: "DESC",
        },
    ];

    sort = null;
    msg = false;
    msgText = "No products were found";
    courses = [];
    total_pages = 0;
    number = [];
    limit = 6;
    offset = 0;
    pageSize: any[] = [];
    lang = "en";
    Home: any;
    CoursesList: any;
    Showing: any;
    results: any;
    of: any;
    AddToCart: any;
    RemovefromCart: any;
    user: any = null;
    email2: any = null;
    name = null;

    constructor(
        private auth: AuthenticationService,
        private _course: CourseService,
        private _cart: CartService,
        private _defult: DefaultService,
        public translate: TranslateService,
        private _order: OrderService,
        private routerNavigation: Router
    ) {}

    ngOnInit(): void {
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
            this.email2 = this.user.email;
            this.name = this.user.firstName + " " + this.user.lastName;
        }

        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }
        this.getLabels();

        this.getData();
        var app = this;
        $(document).ready(function () {
            $(".allCoursesSelect").change(function () {
                app.getData($(this).val(), 2, 0);
            });
        });
    }

    getLabels() {
        this.translate
            .get([
                "Home",
                "CoursesList",
                "Showing",
                "results",
                "of",
                "AddToCart",
                "RemovefromCart",
            ])
            .subscribe((res) => {
                this.Home = res.Home;
                this.CoursesList = res.CoursesList;
                this.Showing = res.Showing;
                this.results = res.results;
                this.of = res.of;
                this.AddToCart = res.AddToCart;
                this.RemovefromCart = res.RemovefromCart;
            });
    }

    changeOffset() {
        this.getData();
    }

    getData(sort?: any, limit?: any, offset?: any) {
        this.msg = false;
        var sortValue = null;
        var sortType = null;

        if (sort != null) {
            let sort2 = this.filterSort.find((e) => e.id == sort);
            sortValue = sort2.value;
            sortType = sort2.type;
        }

        let per_page = this.limit;
        let page = this.offset;

        if (limit != null) {
            per_page = limit;
        }

        if (offset != null) {
            page = offset;
        }

        this._course
            .getAllCourses(null, "", sortValue, sortType, per_page, page)
            .subscribe(
                (res) => {
                    // console.log(res)
                    // to check if more courses exists
                    if (res.apiStatus) {
                        this.courses = res.data;

                        if (this.courses.length == 0) {
                            this.msg = true;
                        } else {
                            var count = res.count;
                            this.total_pages = Math.ceil(count / this.limit);
                            this.number = Array.from(
                                { length: this.total_pages },
                                (v, k) => k + 1
                            );
                        }
                    }
                },
                (err) => {
                    this.msg = true;

                    // console.log(err)
                },
                () => {
                    //  console.log('sucess');
                }
            );
    }
    //check if item exists
    isCourseExist(course) {
        if (this._cart.itemInCart(course)) {
            return true;
        } else {
            return false;
        }
    }

    //add to cart
    addToCart(course) {
        if (!this._cart.itemInCart(course)) {
            this._cart.addToCart(course); //add items in cart
        }
    }

    // remove course from cart
    removeFromCart(course) {
        if (this._cart.itemInCart(course)) {
            this._cart.removeItem(course); //add items in cart
        }
    }

    enroll(e) {
        if (this.user) {
            let app = this;
            Swal.fire({
                title: "Are you sure?",
                text: "Do you want to get  this course?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, i want!",
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    app.sureEnroll(e)
                        .then((data) =>
                            Swal.fire(
                                "Done!",
                                "This course has been added to you",
                                "success"
                            )
                        )
                        .catch((data) => Swal.fire("Error", data, "error"));
                }
            });
        } else {
            this.routerNavigation.navigateByUrl("/login");
        }
    }

    sureEnroll(e): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            let total = 0;
            this._order
                .createOne(
                    0,
                    null,
                    1,
                    this.user.id,
                    total,
                    this.email2,
                    this.name
                )
                .subscribe(
                    (res) => {
                        if (res.apiStatus) {
                            this._cart
                                .createOne(
                                    this.user.id,
                                    e.id,
                                    0,
                                    0,
                                    e.cat_id,
                                    res.data.id,
                                    this.email2,
                                    this.name
                                )

                                .subscribe(
                                    (res) => {
                                        if (res.apiStatus) {
                                            resolve(true);
                                        } else {
                                            reject(true);
                                        }
                                    },
                                    (err) => {
                                        reject(true);
                                    }
                                );
                        } else {
                            reject(true);
                        }
                    },
                    (err2) => {
                        //console.log(err2)
                        reject(true);
                    }
                );
        });

        return promise;
    }
}
