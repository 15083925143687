import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment.prod";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";
import { AuthenticationService } from "src/app/services/auth.service";
import { StudentService } from "src/app/services/student.service";
import { DefaultService } from "src/app/services/default.service";
import { TranslateService } from "@ngx-translate/core";
import { InstructorService } from "src/app/services/instructor.service";
declare var $: any;
@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
    btnTabs: any[] = [];
    idTab: string = "";
    user: any = {};

    baseUrl: string = environment.BaseUrl;
    imageLink: any;
    msg: any = null;
    lang = "en";
    Profile: any;
    Home: any;
    Menu: any;
    BasicInformation: any;
    Name: any;
    LastName: any;
    Telephone: any;
    Email: any;
    DateOfBirth: any;
    Save: any;
    msgtxt: any;
    success = false;

    constructor(
        private auth: AuthenticationService,
        private router: Router,
        public translate: TranslateService,
        private student: StudentService,
        private instructor: InstructorService,
        private _defult: DefaultService
    ) {
        if (this.auth.currentUser()) {
            this.user = this.auth.currentUser();
            // console.log(this.user);

            this.imageBase64 = this.baseUrl + "/" + this.user.image;
        } else {
            this.router.navigateByUrl("/");
        }

        this.btnTabs.push(
            {
                ar_name: "الملف الشخصي",
                en_name: "My profile",
                id: "profile",
                active: true,
            },
            {
                ar_name: "تغيير الباسورد",
                en_name: "Change password",
                id: "changePassword",
                active: false,
            }
        );
        this.idTab = "profile";
        this.btnTabs.push(
            {
                ar_name: "الدورات",
                en_name: "My Courses",
                id: "courses",
                active: false,
            },
            {
                ar_name: "الكتب",
                en_name: "My Books",
                id: "books",
                active: false,
            }
        );

        if (this.user.type == "student") {
            this.btnTabs.push({
                ar_name: "الشهادات",
                en_name: "My certificates",
                id: "certificates",
                active: false,
            });
        }
    }

    ngOnInit(): void {
        if (this._defult.getDefult().langId == 2) {
            this.lang = "ar";
            this.translate.use("ar");
        } else {
            this.lang = "en";
            this.translate.use("en");
        }

        this.getLabels();
    }

    getLabels() {
        this.translate
            .get([
                "Profile",
                "Home",
                "Menu",
                "BasicInformation",
                "Name",
                "LastName",
                "Telephone",
                "Email",
                "DateOfBirth",
                "Save",
            ])
            .subscribe((res) => {
                this.Profile = res.Profile;
                this.Home = res.Home;
                this.BasicInformation = res.BasicInformation;
                this.Name = res.Name;
                this.LastName = res.LastName;
                this.Telephone = res.Telephone;
                this.Email = res.Email;
                this.DateOfBirth = res.DateOfBirth;
                this.Save = res.Save;
                this.Menu = res.Menu;
            });
    }

    changeTab(id: any) {
        this.idTab = id;
        for (var i = 0; i < this.btnTabs.length; i++) {
            if (this.btnTabs[i].id == id) {
                this.btnTabs[i].active = true;
                if (this.btnTabs[i].id == "wilist") {
                    this.router.navigate(["/wishlist/"]);
                }
            } else {
                this.btnTabs[i].active = false;
            }
        }
    }

    clickImage(fileElement: any) {
        fileElement.click();
    }

    imageChangedEvent: any = "";
    croppedImage: any = "";
    imageNull: boolean = true;
    imageBase64: any = "";

    setImage(event: any, image: any) {
        var files = event.target.files;
        $("#modelImageCrop").modal("show");
        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = () => {
                this.imageBase64 = fr.result;
            };
            fr.readAsDataURL(files[0]);
        }
    }
    closeModel(id) {
        $("#" + id).modal("hide");
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    saveImage() {
        this.imageBase64 = this.croppedImage;
        this.imageNull = false;
        this.closeModel("modelImageCrop");
    }
    setProfile(firstName, lastName, email, phone) {
        if (this.user.type == "student") {
            this.student
                .updateProfile(
                    this.user.id,
                    firstName.value,
                    lastName.value,
                    email.value,
                    phone.value
                )
                .subscribe(
                    (res) => {
                        if (res.apiStatus) {
                            if (!this.imageNull) {
                                this.student
                                    .uploadProfilePhoto(
                                        base64ToFile(this.imageBase64),
                                        email.value
                                    )
                                    .subscribe(
                                        (res) => {
                                            this.imageLink = res.data;
                                            this.auth.login({
                                                id: this.user.id,
                                                firstName: firstName.value,
                                                lastName: lastName.value,
                                                token: this.user.token,
                                                email: email.value,
                                                phone: phone.value,
                                                status: this.user.status,
                                                image: this.imageLink,
                                                type: "student",
                                            });
                                            this.msg = true;
                                            this.success = true;
                                            this.msgtxt = "save succesefully";
                                        },
                                        (err) => {
                                            this.success = false;
                                            this.msg = true;
                                            this.msgtxt = "Error is happend";
                                        }
                                    );
                            } else {
                                this.msg = true;
                                this.success = true;
                                this.msgtxt = "save succesefully";
                            }

                            if (this.imageNull) {
                                this.imageLink = this.auth.currentUser().image;
                                this.auth.login({
                                    id: this.user.id,
                                    firstName: firstName.value,
                                    lastName: lastName.value,
                                    token: this.user.token,
                                    email: email.value,
                                    phone: phone.value,
                                    status: this.user.status,
                                    image: this.imageLink,
                                    type: "student",
                                });
                            }

                            this.user = this.auth.currentUser();
                        }
                    },
                    (err) => {
                        //console.log(err)
                    }
                );
        } else {
            this.instructor
                .updateProfile(
                    this.user.id,
                    firstName.value,
                    lastName.value,
                    email.value,
                    phone.value
                )
                .subscribe(
                    (res) => {
                        if (res.apiStatus) {
                            if (!this.imageNull) {
                                this.instructor
                                    .uploadProfilePhoto(
                                        base64ToFile(this.imageBase64),
                                        email.value
                                    )
                                    .subscribe(
                                        (res) => {
                                            this.imageLink = res.data;
                                            this.auth.login({
                                                id: this.user.id,
                                                firstName: firstName.value,
                                                lastName: lastName.value,
                                                token: this.user.token,
                                                email: email.value,
                                                phone: phone.value,
                                                status: this.user.status,
                                                image: this.imageLink,
                                                type: "instructor",
                                            });
                                            this.msg = true;
                                            this.success = true;
                                            this.msgtxt = "save succesefully";
                                        },
                                        (err) => {
                                            this.success = false;
                                            this.msg = true;
                                            this.msgtxt = "Error is happend";
                                        }
                                    );
                            } else {
                                this.msg = true;
                                this.success = true;
                                this.msgtxt = "save succesefully";
                            }

                            if (this.imageNull) {
                                this.imageLink = this.auth.currentUser().image;
                                this.auth.login({
                                    id: this.user.id,
                                    firstName: firstName.value,
                                    lastName: lastName.value,
                                    token: this.user.token,
                                    email: email.value,
                                    phone: phone.value,
                                    status: this.user.status,
                                    image: this.imageLink,
                                    type: "instructor",
                                });
                            }

                            this.user = this.auth.currentUser();
                        }
                    },
                    (err) => {
                        //console.log(err)
                    }
                );
        }
    }
}
